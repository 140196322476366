import React from 'react'
import { Popover, Box, Theme, makeStyles } from '@material-ui/core'
import { ReactComponent as SettingsIcon } from 'src/images/navbar/settings.svg'
import { ReactComponent as SubscriptionIcon } from 'src/images/navbar/subscription.svg'
import { theme } from 'src/styling'
import { recordUserInteraction, userLoggedOut } from 'src/services/posthog'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { ReactComponent as LogoutIcon } from 'src/images/navbar/log-out.svg'
import { OrganisationTabsType } from 'src/routers/router-helpers'
import { useHistory } from 'react-router-dom'
import GroupIcon from '@material-ui/icons/Group'

// ===================
// TYPES
// ===================

type Props = {
  isOpen: boolean
  anchorElProfile: HTMLElement
  isNavbarOpen: boolean
  setIsProfilePopoverOpen: (state: boolean) => void
  viewingAsThirdParty: boolean
  userOrganisationId: number
  organisationId: number
  routes: OrganisationTabsType
  isAdmin: boolean
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles<Theme>({
  navbarLinkOff: {
    cursor: 'default',
    opacity: 0.4,
    pointerEvents: 'all',
  },
  navbarLogoutIcon: {
    width: '0.9rem',
    transform: 'rotate(180deg)',
  },
  profileIconPopoverContainer: { display: 'flex' },
  profileIconPopoverInnerContainer: {
    background: theme.colors.ui.blueDark,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  profileIconPopoverSegment: {
    display: 'flex',
    gap: '.5rem',
    padding: '15px',
    '&:hover': { backgroundColor: theme.colors.ui.blueLight },
  },
  profileIconPopoverArrow: {
    position: 'relative',
    marginLeft: '10px',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 'calc(50% - 10px)',
      left: '-9px',
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '10px solid transparent',
      borderRight: `10px solid ${theme.colors.ui.blueDark}`,
    },
  },
  accountPopover: {
    '& .MuiPopover-paper': {
      transition: `opacity 238ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 159ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 0.5s ease 0s !important`,
    },
  },
})

const UserProfile = ({
  isAdmin,
  isOpen,
  anchorElProfile,
  isNavbarOpen,
  setIsProfilePopoverOpen,
  viewingAsThirdParty,
  organisationId,
  userOrganisationId,
  routes,
}: Props) => {
  const userHasSettingsPermission = routes?.['/settings']
  const { isInsuranceUseCase } = useSelector((state) => state.auth)
  const history = useHistory()
  const classes = useStyles({})
  const dispatch = useDispatch()
  const handleLogout = (): void => {
    localStorage.setItem('rbvmDownload', 'false')
    recordUserInteraction(userLoggedOut)
    dispatch({
      type: 'UPDATE_THIRD_PARTY_COMPANY_NAME',
      payload: { thirdPartyCompanyName: '' },
    })
    dispatch({
      type: 'LOGOUT',
      payload: {
        nextUrl: '/',
      },
    })
  }
  return (
    <Popover
      className={classes.accountPopover}
      open={isOpen}
      anchorEl={anchorElProfile}
      anchorOrigin={{ vertical: 'top', horizontal: isNavbarOpen ? 142 : 49 }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderRadius: 0,
        },
      }}
    >
      <div
        className={classes.profileIconPopoverContainer}
        onMouseEnter={() => setIsProfilePopoverOpen(true)}
        onMouseLeave={() => setIsProfilePopoverOpen(false)}
      >
        <Box className={classes.profileIconPopoverArrow} />
        <div className={classes.profileIconPopoverInnerContainer}>
          {((routes && routes['/settings']) || isAdmin) && (
            <>
              <div
                className={`${classes.profileIconPopoverSegment} 
            ${viewingAsThirdParty || !userHasSettingsPermission ? classes.navbarLinkOff : ''}`}
                onClick={() => (viewingAsThirdParty || !userHasSettingsPermission ? null : history.push('/settings'))}
                data-tip
                data-for="tooltip-settings"
              >
                <SettingsIcon width={18} />
                Settings
              </div>
              <ReactTooltip
                disable={!routes ? true : routes && userHasSettingsPermission ? true : false}
                backgroundColor={theme.colors.ui.blueDark}
                id={`tooltip-settings`}
                place="right"
                effect="solid"
              >
                {viewingAsThirdParty
                  ? `Page not available - currently viewing as ${isInsuranceUseCase ? 'portfolio' : 'third party'}`
                  : 'Please upgrade your subscription to access this page'}
              </ReactTooltip>
            </>
          )}
          {routes && routes['/users'] && (
            <>
              <div
                data-tip
                data-for="tooltip-users"
                className={`${classes.profileIconPopoverSegment}`}
                onClick={() => history.push('/users')}
              >
                <GroupIcon style={{ position: 'relative', bottom: '0.2rem' }} width={18} />
                Users
              </div>
            </>
          )}
          {routes && routes['/subscription'] && (
            <>
              <div className={`${classes.profileIconPopoverSegment}`} onClick={() => history.push('/subscription')}>
                <SubscriptionIcon width={18} />
                Subscription
              </div>
            </>
          )}
          <div className={classes.profileIconPopoverSegment} onClick={handleLogout}>
            <div className={classes.navbarLogoutIcon}>
              <LogoutIcon width={18} />
            </div>
            Logout
          </div>
        </div>
      </div>
    </Popover>
  )
}

export default UserProfile
