import React from 'react'
import NotFound from 'src/images/errors/404.png'
import InternalServerError from 'src/images/errors/500.png'
import AccessDenied from 'src/images/errors/401.png'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { theme } from 'src/styling'
import { getJwtPayload } from 'src/auth/payload'
import { useSelector } from 'react-redux'

type ErrorProps = {
  errorCode?: string
  disableImage?: boolean
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles(() => ({
  iconContainer: {
    width: '60px',
  },
  image: { width: '3.5rem', height: '3.5rem' },
  errorContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    marginTop: '40px',
  },
  title: {
    fontSize: theme.fontSizes.large,
    fontWeight: 500,
    padding: '0.9rem',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: theme.fontSizes.medium,
    fontWeight: 400,
    textAlign: 'center',
    '& a': {
      fontSize: theme.fontSizes.medium,
    },
  },
}))
// ===================
// COMPONENT
// ===================
const Error: React.FC<ErrorProps> = ({ errorCode, disableImage = false }) => {
  const classes = useStyles()
  const { viewingAsThirdParty } = getJwtPayload()
  const { isInsuranceUseCase } = useSelector((state) => state.auth)

  return (
    <Box className={classes.errorContainer}>
      {errorCode === 'NOT_FOUND' && (
        <>
          <Box className={classes.iconContainer}>
            <img className={classes.image} src={NotFound} />
          </Box>

          <Box className={classes.title}>Page does not exist!</Box>
        </>
      )}

      {errorCode === 'FORBIDDEN' && (
        <>
          <Box className={classes.iconContainer}>
            <img className={classes.image} src={AccessDenied} />
          </Box>
          {viewingAsThirdParty ? (
            <>
              <Box className={classes.title}>
                This page is not available while viewing a {isInsuranceUseCase ? 'portfolio' : 'third party'} company
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.title}>Please upgrade your subscription to access this page.</Box>
              <Box className={classes.subTitle}>
                <a href="mailto:contact@orpheus-cyber.com">Contact us</a> to upgrade
              </Box>
            </>
          )}
        </>
      )}

      {(errorCode === 'INTERNAL_SERVER_ERROR' || !errorCode) && (
        <>
          {!disableImage && (
            <Box className={classes.iconContainer}>
              <img className={classes.image} src={InternalServerError} />
            </Box>
          )}

          <Box className={classes.title}>There was an issue processing your request.</Box>
          <Box className={classes.subTitle}>Please try again later.</Box>
        </>
      )}
    </Box>
  )
}

export default Error
