import * as JWTDecode from 'jwt-decode'

import Cookies from 'js-cookie'

export type OrganisationTabsType = {
  [route: string]: number
}
import { rootStore } from 'src/redux/store'

export const getRouteFromPayload = (pathname: string): number => {
  try {
    const token = Cookies.get('auth-token')
    const decodedToken = JWTDecode(token)
    const pageName = `/${pathname.split('/')[1]}`
    const tab = decodedToken.data.organisationTabs[pageName]
    if (tab !== undefined) return tab
    return 1
  } catch (error) {
    return 0
  }
}

export const getRoutesFromPayload = (): OrganisationTabsType => {
  try {
    const token = Cookies.get('auth-token')
    const decodedToken = JWTDecode(token)
    const tabs = decodedToken.data.organisationTabs
    return tabs
  } catch (error) {
    return null
  }
}

export const getFirstActiveTab = () => {
  try {
    const token = Cookies.get('auth-token')
    const decodedToken = JWTDecode(token)
    const tabs = decodedToken.data.organisationTabs
    const pageOrder = [
      '/external-asm',
      '/internal-rbvm',
      '/third-party-risk',
      '/intelligence',
      '/threat',
      '/profiles',
      '/explore',
      '/reports',
      '/your-report',
      '/alerts',
      '/settings',
      '/subscription',
    ]
    const state = rootStore.getState()
    const { isInsuranceUseCase } = state.auth
    let firstActiveTab = isInsuranceUseCase
      ? '/third-party-risk'
      : tabs == null
      ? '/external-asm'
      : pageOrder.find((page) => tabs[page]) || '/error'
    return firstActiveTab
  } catch (error) {
    return '/login'
  }
}
