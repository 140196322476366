import auth0 from 'src/auth/auth0'
import Cookies from 'js-cookie'
import { clearPostHogSession } from 'src/services/posthog'

// Actions
type LoginAction = {
  type: 'LOGIN'
  payload: {
    firstName: string
    organisationName: string
    pocExpiresInDays: number
    pocExpired: boolean
    isNewUser: boolean
    email: string
    isInsuranceUseCase: boolean
    freeTrialExpired: {
      priceId: string
      customerId: string
      couponId: string
    }
  }
}
type LogoutAction = {
  type: 'LOGOUT'
  payload: {
    firstName: string
    organisationName: string
    pocExpiresInDays: number
  }
}
type authorizeAction = {
  type: 'AUTHORIZE'
  payload: {
    nextUrl: string
  }
}
export type AuthReducerActions = LoginAction | LogoutAction | authorizeAction

// State
export type AuthReducerState = {
  isAuthenticated: boolean
  nextUrl: string
  firstName: string
  organisationName: string
  pocExpiresInDays: number
  pocExpired: boolean
  isNewUser: boolean
  email: string
  isInsuranceUseCase: boolean
  freeTrialExpired: {
    priceId: string
    customerId: string
    couponId: string
  }
}

type AuthReducer = {
  (state: AuthReducerState, action: AuthReducerActions): AuthReducerState
}

const initialState: any = {
  isAuthenticated: false,
  nextUrl: '/',
  firstName: '',
  organisationName: '',
  pocExpiresInDays: null,
  pocExpired: false,
  freeTrialExpired: null,
  isNewUser: null,
  isInsuranceUseCase: null,
}

const authReducer: AuthReducer = (state = initialState, action) => {
  const newState = { ...state }

  switch (action.type) {
    case 'LOGOUT':
      clearPostHogSession()
      Cookies.remove('auth-token')
      sessionStorage.removeItem('urlOnPageLoad')
      auth0.logout()
      state = undefined
      return state

    case 'LOGIN':
      newState.isAuthenticated = true
      newState.firstName = action.payload.firstName
      newState.organisationName = action.payload.organisationName
      newState.pocExpiresInDays = action.payload.pocExpiresInDays
      newState.pocExpired = action.payload.pocExpired
      newState.freeTrialExpired = action.payload.freeTrialExpired
      newState.isNewUser = action.payload.isNewUser
      newState.email = action.payload.email
      newState.isInsuranceUseCase = action.payload.isInsuranceUseCase
      auth0.logout()
      return newState

    // TODO: this is only called to log someone out
    case 'AUTHORIZE':
      auth0.authorize()
      const token = document.cookie.includes('auth-token')
      const { pathname, search } = window.location
      const urlOnPageLoad = pathname + search

      if (!token && pathname !== '/' && pathname !== '/login') sessionStorage.setItem('urlOnPageLoad', urlOnPageLoad)

      if (!token && pathname === '/login' && !!search.length) sessionStorage.setItem('searchParamsOnLogin', search)

      localStorage.setItem('preloader-state', 'needsFadeIn')
      newState.nextUrl = action.payload.nextUrl
      return newState

    default:
      return state
  }
}

export default authReducer
